{
  "referrer_journey": {
    "capture_referrer_details": {
      "content": {
        "locale": {
          "title": {
            "type": "string",
            "required": true,
            "long": true
          },
          "offer_details": {
            "collapsed_title": {
              "type": "string",
              "required": true
            },
            "expanded_title": {
              "type": "string",
              "required": true
            },
            "steps[0]": {
              "type": "string",
              "required": true,
              "long": true
            },
            "steps[1]": {
              "type": "string",
              "required": true,
              "long": true
            },
            "steps[2]": {
              "type": "string",
              "required": true,
              "long": true
            },
            "footer": {
              "type": "string",
              "required": true,
              "long": true
            }
          },
          "form": {
            "caption": {
              "type": "string",
              "required": true
            },
            "button": {
              "text": {
                "type": "string",
                "required": true
              }
            },
            "sign_in_link_emailed_message": {
              "type": "string",
              "required": true,
              "long": true
            },
            "eligibility_check_rejection_message": {
              "type": "string",
              "required": true,
              "long": true
            },
            "submit_error": {
              "type": "string",
              "required": true
            },
            "fine_print": {
              "type": "string",
              "required": true,
              "long": true
            }
          },
          "additional_fine_print": {
            "type": "string",
            "required": true,
            "key_optional": true,
            "long": true
          },
          "image": {
            "url": {
              "type": "string",
              "required": true
            },
            "alt_text": {
              "type": "string",
              "required": true,
              "canBeEmpty": true
            },
            "mobile": {
              "key_optional": true,
              "url": {
                "type": "string",
                "required": true
              },
              "alt_text": {
                "type": "string",
                "required": true
              }
            }
          }
        }
      }
    },
    "shared": {
      "content": {
        "locale": {
          "inputs": {
            "full_name": {
              "label": {
                "type": "string",
                "required": true
              },
              "placeholder": {
                "type": "string",
                "required": true
              },
              "validation_errors": {
                "type": "validation_error",
                "required": true
              },
              "tooltip": {
                "type": "string",
                "required": false
              }
            },
            "first_name": {
              "label": {
                "type": "string",
                "required": true
              },
              "placeholder": {
                "type": "string",
                "required": true
              },
              "validation_errors": {
                "type": "validation_error",
                "required": true
              },
              "tooltip": {
                "type": "string",
                "required": false
              }
            },
            "last_name": {
              "label": {
                "type": "string",
                "required": true
              },
              "placeholder": {
                "type": "string",
                "required": true
              },
              "validation_errors": {
                "type": "validation_error",
                "required": true
              },
              "tooltip": {
                "type": "string",
                "required": false
              }
            },
            "email": {
              "label": {
                "type": "string",
                "required": true
              },
              "placeholder": {
                "type": "string",
                "required": true
              },
              "validation_errors": {
                "type": "validation_error",
                "required": true
              },
              "tooltip": {
                "type": "string",
                "required": false
              }
            },
            "custom_fields": {
              "type": "custom_fields",
              "path": "referrer_journey.shared.settings.inputs.custom_fields"
            },
            "marketing_opt_in": {
              "label": {
                "type": "string",
                "required": true
              },
              "validation_error": {
                "type": "string",
                "required": true
              }
            },
            "terms_opt_in": {
              "label": {
                "type": "string",
                "required": true
              },
              "validation_error": {
                "type": "string",
                "required": true
              }
            }
          }
        }
      },
      "settings": {
        "inputs": {
          "full_name": {
            "show": {
              "type": "boolean"
            },
            "min_length": {
              "type": "number",
              "required": true
            }
          },
          "first_name": {
            "show": {
              "type": "boolean"
            },
            "min_length": {
              "type": "number",
              "required": true
            }
          },
          "last_name": {
            "show": {
              "type": "boolean"
            },
            "min_length": {
              "type": "number",
              "required": true
            }
          },
          "email": {
            "show": {
              "type": "boolean"
            }
          },
          "marketing_opt_in": {
            "show": {
              "type": "boolean"
            },
            "required": {
              "type": "boolean"
            }
          },
          "terms_opt_in": {
            "show": {
              "type": "boolean"
            }
          },
          "custom_fields": {
            "type": "custom_fields_settings"
          }
        },
        "offer_details": {
          "show_step_icons": {
            "type": "boolean"
          },
          "reveal_on_load": {
            "type": "boolean"
          }
        },
        "eligibility_check": {
          "key_optional": true,
          "type": "eligibility_check",
          "required": true
        },
        "button": {
          "show": {
            "type": "boolean",
            "required": true
          }
        }
      }
    },
    "referrer_dashboard": {
      "content": {
        "locale": {
          "title": {
            "type": "string",
            "required": true,
            "long": true
          },
          "offer_details": {
            "collapsed_title": {
              "type": "string",
              "required": true
            },
            "expanded_title": {
              "type": "string",
              "required": true
            },
            "steps[0]": {
              "type": "string",
              "required": true,
              "long": true
            },
            "steps[1]": {
              "type": "string",
              "required": true,
              "long": true
            },
            "steps[2]": {
              "type": "string",
              "required": true,
              "long": true
            },
            "footer": {
              "type": "string",
              "required": true,
              "long": true
            }
          },
          "logout": {
            "message": {
              "type": "string",
              "required": true,
              "long": false
            },
            "link_text": {
              "type": "string",
              "required": true,
              "long": false
            }
          },
          "share_message": {
            "type": "string",
            "required": true,
            "long": true
          },
          "manual_sharing": {
            "title": {
              "type": "string",
              "required": true,
              "long": false
            },
            "copy_button_text": {
              "type": "string",
              "required": true,
              "long": false
            },
            "copy_success_notification": {
              "type": "string",
              "required": true,
              "long": false
            }
          },
          "native_sharing": {
            "title": {
              "type": "string",
              "required": true,
              "long": false
            },
            "button_text": {
              "type": "string",
              "required": true,
              "long": false
            }
          },
          "share_code": {
            "label": {
              "type": "string",
              "required": true
            },
            "copy_button_text": {
              "type": "string",
              "required": true
            },
            "copy_success_notification": {
              "type": "string",
              "required": true
            },
            "share_message": {
              "type": "string",
              "required": true
            },
            "native_sharing": {
              "title": {
                "type": "string",
                "required": true
              },
              "button_text": {
                "type": "string",
                "required": true
              }
            }
          },
          "referral_status": {
            "stats": {
              "friend_visit_count": {
                "type": "string",
                "required": true,
                "long": false
              },
              "friend_registration_count": {
                "type": "string",
                "required": true,
                "long": false
              },
              "pending_referral_count": {
                "type": "string",
                "required": true,
                "long": false
              },
              "complete_referral_count": {
                "type": "string",
                "required": true,
                "long": false
              },
              "cancelled_referral_count": {
                "type": "string",
                "required": true,
                "long": false
              }
            },
            "pill": {
              "collapsed": {
                "type": "string",
                "required": true,
                "long": false
              },
              "expanded": {
                "type": "string",
                "required": true,
                "long": false
              }
            },
            "table": {
              "headers": {
                "name": {
                  "type": "string",
                  "required": true,
                  "long": false
                },
                "date": {
                  "type": "string",
                  "required": true,
                  "long": false
                },
                "status": {
                  "type": "string",
                  "required": true,
                  "long": false
                }
              },
              "pending": {
                "title": {
                  "type": "string",
                  "required": true,
                  "long": false
                },
                "tooltip": {
                  "type": "string",
                  "required": true,
                  "long": false
                }
              },
              "complete": {
                "title": {
                  "type": "string",
                  "required": true,
                  "long": false
                },
                "tooltip": {
                  "type": "string",
                  "required": true,
                  "long": false
                }
              },
              "statuses": {
                "pending": {
                  "type": "string",
                  "required": true,
                  "long": false
                },
                "complete": {
                  "type": "string",
                  "required": true,
                  "long": false
                }
              }
            }
          },
          "reward_capping": {
            "warning_title": {
              "type": "string",
              "required": true,
              "long": false
            },
            "volume_warning_message": {
              "type": "string",
              "required": true,
              "long": true
            },
            "monetary_warning_message": {
              "type": "string",
              "required": true,
              "long": true
            }
          },
          "fine_print": {
            "type": "string",
            "required": true,
            "long": true
          },
          "image": {
            "url": {
              "type": "string",
              "required": true
            },
            "alt_text": {
              "type": "string",
              "required": true,
              "canBeEmpty": true
            },
            "mobile": {
              "key_optional": true,
              "url": {
                "type": "string",
                "required": true
              },
              "alt_text": {
                "type": "string",
                "required": true
              }
            }
          }
        }
      },
      "settings": {
        "show_referral_statuses": {
          "type": "boolean",
          "required": true
        },
        "show_pending_referrals": {
          "type": "boolean",
          "required": true
        },
        "share_networks": {
          "type": "share_networks"
        },
        "referral_stats": {
          "type": "referral_stats"
        },
        "show_direct_link": {
          "type": "boolean",
          "required": true
        },
        "show_share_code": {
          "type": "boolean",
          "required": true
        }
      }
    },
    "splash_page": {
      "content": {
        "locale": {
          "title": {
            "type": "string",
            "required": true,
            "long": true
          },
          "offer_details": {
            "collapsed_title": {
              "type": "string",
              "required": true
            },
            "expanded_title": {
              "type": "string",
              "required": true
            },
            "steps[0]": {
              "type": "string",
              "required": true,
              "long": true
            },
            "steps[1]": {
              "type": "string",
              "required": true,
              "long": true
            },
            "steps[2]": {
              "type": "string",
              "required": true,
              "long": true
            },
            "footer": {
              "type": "string",
              "required": true,
              "long": true
            }
          },
          "form": {
            "caption": {
              "type": "string",
              "required": true
            },
            "button": {
              "text": {
                "type": "string",
                "required": true
              }
            },
            "submit_error": {
              "type": "string",
              "required": true
            },
            "eligibility_check_rejection_message": {
              "type": "string",
              "required": true,
              "long": true
            },
            "fine_print": {
              "type": "string",
              "required": true,
              "long": true
            }
          },
          "additional_fine_print": {
            "type": "string",
            "required": false,
            "long": true,
            "key_optional": true
          },
          "image": {
            "url": {
              "type": "string",
              "required": true
            },
            "alt_text": {
              "type": "string",
              "required": true,
              "canBeEmpty": true
            }
          }
        }
      },
      "settings": {
        "layout": "default"
      }
    }
  }
}
